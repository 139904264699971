
import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/Index.vue'

const secondaryAgentRouter: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    meta: {
      roles: ['secondaryDistributor', 'secondaryDivider'],
      affix: true
    },
    children: [
      {
        path: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/secondary/Basicinfo.vue'),
        name: 'Dashboard',
        meta: {
          title: 'secondaryIndex',
          icon: '#icondashboard',
          order: 1,
          roles: ['secondaryDistributor', 'secondaryDivider']
        }
      },
      {
        path: 'order',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/order/Index.vue'),
        name: 'SecondaryOrder',
        meta: {
          title: 'secondaryList',
          icon: '#iconexample',
          roles: ['secondaryDistributor', 'secondaryDivider'],
          hidden: true,
          activeMenu: '/'
        }
      },
      {
        path: '/changepassword/index',
        component: () => import(/* webpackChunkName: "userManager" */ '@/views/user-manager/changepassword/Index.vue'),
        name: 'Changepassword',
        meta: {
          title: 'changePassword',
          hidden: true,
          activeMenu: '/profile/index',
          roles: ['secondaryDistributor', 'secondaryDivider']
        }
      }
    ]
  }
]

export default secondaryAgentRouter
