
class Keys {
  static sidebarStatusKey = 'famigurad-retail-sidebarStatusKey'
  static languageKey = 'famigurad-retail-languageKey'
  static sizeKey = 'famigurad-retail-sizeKey'
  static tokenKey = 'famigurad-retail-access-token'
  static aseKey = 'famigurad-retail-ase-key'
}

export default Keys
