import { MutationTree } from 'vuex'
import { UserState, UserInfo, Company } from './state'
import { UserMutationTypes } from './mutation-types'

export type Mutations<S = UserState> = {
  [UserMutationTypes.SET_CAPTCHACODE](state: S, captchacode: string): void
  [UserMutationTypes.SET_CAPTCHAIMGSRC](state: S, captchaimgsrc: string): void
  [UserMutationTypes.SET_LOGINERROR](state: S, loginerror: string): void
  [UserMutationTypes.SET_TOKEN](state: S, token: string): void
  [UserMutationTypes.SET_USERINFO](state: S, userinfo: UserInfo): void
  [UserMutationTypes.SET_NAME](state: S, name: string): void
  [UserMutationTypes.SET_AVATAR](state: S, avatar: string): void
  [UserMutationTypes.SET_INTRODUCTION](state: S, introduction: string): void
  [UserMutationTypes.SET_ROLES](state: S, roles: string[]): void
  [UserMutationTypes.SET_EMAIL](state: S, email: string): void
  [UserMutationTypes.SET_ACCOUNT](state: S, account: number): void
  [UserMutationTypes.SET_BANKACCOUNT](state: S, bankaccount: string): void
  [UserMutationTypes.SET_COMPANY](state: S, company: Company): void
  [UserMutationTypes.SET_ACTIVATIONCODE](state: S, activationCode: string): void
  [UserMutationTypes.SET_NOWDAY](state: S, nowday: string): void
}

export const mutations: MutationTree<UserState> & Mutations = {
  [UserMutationTypes.SET_CAPTCHACODE](state: UserState, captchacode: string) {
    state.captchacode = captchacode
  },

  [UserMutationTypes.SET_CAPTCHAIMGSRC](state: UserState, captchaimgsrc: string) {
    state.captchaimgsrc = captchaimgsrc
  },

  [UserMutationTypes.SET_LOGINERROR](state: UserState, loginerror: string) {
    state.loginerror = loginerror
  },

  [UserMutationTypes.SET_TOKEN](state: UserState, token: string) {
    state.token = token
  },

  [UserMutationTypes.SET_USERINFO](state: UserState, userinfo: UserInfo) {
    Object.assign(state.userinfo, userinfo)
  },

  [UserMutationTypes.SET_NAME](state: UserState, name: string) {
    state.name = name
  },

  [UserMutationTypes.SET_AVATAR](state: UserState, avatar: string) {
    state.avatar = avatar
  },

  [UserMutationTypes.SET_INTRODUCTION](state: UserState, introduction: string) {
    state.introduction = introduction
  },

  [UserMutationTypes.SET_ROLES](state: UserState, roles: string[]) {
    state.roles = roles
  },

  [UserMutationTypes.SET_EMAIL](state: UserState, email: string) {
    state.email = email
  },

  [UserMutationTypes.SET_ACCOUNT](state: UserState, account: number) {
    state.account = account
  },

  [UserMutationTypes.SET_BANKACCOUNT](state: UserState, bankaccount: string) {
    state.bankaccount = bankaccount
  },

  [UserMutationTypes.SET_COMPANY](state: UserState, company: Company) {
    Object.assign(state.company, company)
  },

  [UserMutationTypes.SET_ACTIVATIONCODE](state: UserState, activationCode: string) {
    state.activationCode = activationCode
  },

  [UserMutationTypes.SET_NOWDAY](state: UserState, nowday: string) {
    state.nowday = nowday
  }
}
