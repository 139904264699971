import { ActionTree, ActionContext } from 'vuex'
import { RootState, useStore } from '@/store'
import { state, UserState } from './state'
import { Mutations } from './mutations'
import { UserMutationTypes } from './mutation-types'
import { UserActionTypes } from './action-types'
import { loginRequest, verifCodeRequest } from '@/apis/user'
import { removeToken, setToken } from '@/utils/cookies'
import { PermissionActionType } from '../permission/action-types'
import router, { resetRouter } from '@/router'
import { RouteRecordRaw } from 'vue-router'
import storage, { StorageType } from '@/utils/storage'
import { ElMessage } from 'element-plus'

export type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>
} & Omit<ActionContext<UserState, RootState>, 'commit'>

export interface Actions {
  [UserActionTypes.ACTION_CAPTCHA](
    { commit }: AugmentedActionContext,
    captchaInfo: { font_size?: number, length?: number }
  ): void
  [UserActionTypes.ACTION_LOGIN](
    { commit }: AugmentedActionContext,
    userInfo: { agent_name: string, password: string, captcha: string, captcha_uuid: string }
  ): void
  [UserActionTypes.ACTION_RESET_TOKEN](
    { commit }: AugmentedActionContext
  ): void
  [UserActionTypes.ACTION_GET_USER_INFO](
    { commit }: AugmentedActionContext,
    roles: string[]
  ): void
  [UserActionTypes.ACTION_CHANGE_ROLES](
    { commit }: AugmentedActionContext, role: string
  ): void
  [UserActionTypes.ACTION_LOGIN_OUT](
    { commit }: AugmentedActionContext,
  ): void
}

export const actions: ActionTree<UserState, RootState> & Actions = {
  async [UserActionTypes.ACTION_CAPTCHA](
    { commit }: AugmentedActionContext,
    captchaInfo: { font_size?: number, length?: number }
  ) {
    const { font_size = 20, length = 4 } = captchaInfo
    await verifCodeRequest({ font_size, length }).then(async(res) => {
      if (res?.code === 200 && res.data.url) {
        commit(UserMutationTypes.SET_CAPTCHAIMGSRC, res.data.url)
        commit(UserMutationTypes.SET_CAPTCHACODE, res.data.uuid)
      } else {
        console.log(res)
      }
    }).catch((err) => {
      console.log(err)
    })
  },

  async [UserActionTypes.ACTION_LOGIN](
    { commit }: AugmentedActionContext,
    userInfo: { agent_name: string, password: string, captcha: string, captcha_uuid: string }
  ) {
    let { agent_name, password, captcha, captcha_uuid } = userInfo
    agent_name = agent_name.trim()
    storage.rcRemoveItem(StorageType.local, 'now')
    await loginRequest({ agent_name, password, captcha, captcha_uuid }).then(async(res) => {
      if (res?.code === 200 && res.data.token) {
        setToken(res.data.token)
        commit(UserMutationTypes.SET_TOKEN, res.data.token)
        commit(UserMutationTypes.SET_USERINFO, res.data.agent)
        commit(UserMutationTypes.SET_LOGINERROR, '')
        commit(UserMutationTypes.SET_ACCOUNT, parseFloat(res.data.commission.active_amount))
        commit(UserMutationTypes.SET_BANKACCOUNT, res.data.corporate.account)
        let rol = 'alevelDivider'
        if (res.data.agent.pid > 0) {
          rol = res.data.agent.agent_type === 1 ? 'secondaryDivider' : 'secondaryDistributor'
        } else {
          res.data.agent.agent_type === 2 && (rol = 'alevelDistributor')
        }
        storage.rcSetItem(StorageType.local, 'roles', [rol])
        let signData = storage.rcGetItem(StorageType.local, 'signData')
        signData = signData ? JSON.parse(signData) : ''
        storage.rcSetItem(StorageType.local, 'signData', Object.assign(signData, {
          signature: res.data.sign
        }))
        ElMessage.success('登录成功')
      } else if (res?.code) {
        ElMessage.error(res.msg)
      }
    }).catch((err) => {
      console.log(err)
      ElMessage.error(err)
    })
  },

  [UserActionTypes.ACTION_RESET_TOKEN](
    { commit }: AugmentedActionContext) {
    removeToken()
    commit(UserMutationTypes.SET_TOKEN, '')
    commit(UserMutationTypes.SET_ROLES, [])
  },

  [UserActionTypes.ACTION_GET_USER_INFO](
    { commit }: AugmentedActionContext,
    roles: string[]
  ) {
    // if (state.token === '') {
    //   throw Error('token is undefined!')
    // }
    // await userInfoRequest().then((res) => {
    //   if (res?.code === 0) {
    //     // commit(UserMutationTypes.SET_ROLES, res.data.roles)
    //     commit(UserMutationTypes.SET_NAME, res.data.name)
    //     commit(UserMutationTypes.SET_AVATAR, res.data.avatar)
    //     commit(UserMutationTypes.SET_INTRODUCTION, res.data.introduction)
    //     commit(UserMutationTypes.SET_EMAIL, res.data.email)
    //     return res
    //   } else {
    //     throw Error('Verification failed, please Login again.')
    //   }
    // })
    commit(UserMutationTypes.SET_ROLES, roles)
  },

  async [UserActionTypes.ACTION_CHANGE_ROLES](
    { commit }: AugmentedActionContext,
    role: string
  ) {
    const token = role + '-token'
    const store = useStore()
    commit(UserMutationTypes.SET_TOKEN, token)
    setToken(token)
    await store.dispatch(UserActionTypes.ACTION_GET_USER_INFO, [])
    store.dispatch(PermissionActionType.ACTION_SET_ROUTES, state.roles)
    store.state.permission.dynamicRoutes.forEach((item: RouteRecordRaw) => {
      router.addRoute(item)
    })
  },

  [UserActionTypes.ACTION_LOGIN_OUT](
    { commit }: AugmentedActionContext
  ) {
    removeToken()
    commit(UserMutationTypes.SET_TOKEN, '')
    commit(UserMutationTypes.SET_ROLES, [])
    console.log(state.roles)
    // store.dispatch(PermissionActionType.ACTION_SET_ROUTES, state.roles)
    location.reload()
    storage.rcRemoveItem(StorageType.local, 'subStore')
    resetRouter()
  }
}
