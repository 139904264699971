
import { defineComponent } from 'vue'
import settings from '@/config/default/setting.config'

export default defineComponent({
  props: {
    collapse: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const title = settings.title
    return {
      title
    }
  }
})
