import { getToken } from '@/utils/cookies'
import HttpClient, { HttpClientConfig } from 'axios-mapper'
import networkConfig from '@/config/default/net.config'
import storage, { StorageType } from './storage'
interface SignData {
  randomStr: number
  signature: string
  timeStamp: number
}
export const getSignData: () => SignData | {} = () => {
  let signData = storage.rcGetItem(StorageType.local, 'signData')
  signData = signData ? JSON.parse(signData) : {}
  return signData
}
const https = (hasToken: Boolean = true) => {
  const config: HttpClientConfig = {
    baseURL: networkConfig.host,
    headers: {
      'Monimaster-Token': hasToken ? getToken() : ''
    },
    defaultParams: getSignData()
  }
  return new HttpClient(config)
}

export default https
