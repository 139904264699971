
export enum UserMutationTypes {
  SET_CAPTCHACODE = 'SET_CAPTCHACODE',
  SET_CAPTCHAIMGSRC = 'SET_CAPTCHAIMGSRC',
  SET_LOGINERROR = 'SET_LOGINERROR',
  SET_TOKEN = 'SET_TOKEN',
  SET_USERINFO = 'SET_USERINFO',
  SET_NAME = 'SET_NAME',
  SET_AVATAR = 'SET_AVATAR',
  SET_INTRODUCTION = 'SET_INTRODUCTION',
  SET_ROLES = 'SET_ROLES',
  SET_EMAIL = 'SET_EMAIL',
  SET_ACCOUNT = 'SET_ACCOUNT',
  SET_BANKACCOUNT = 'SET_BANKACCOUNT',
  SET_COMPANY = 'SET_COMPANY',
  SET_ACTIVATIONCODE = 'SET_ACTIVATIONCODE',
  SET_NOWDAY = 'SET_NOWDAY'
}
