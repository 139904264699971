import { RootObject } from '@/model/rootObject'
import { UserInfoModel, Users } from '@/model/userModel'
import https from '@/utils/https'
import { RequestParams, ContentType, Method } from 'axios-mapper'
import { UserInfo } from '@/store/modules/user/state'
import { getRandom } from '@/utils'
import storage, { StorageType } from '@/utils/storage'
import { IndexMessage } from '@/model/orderModel'

export const getSignData: () => IndexMessage<undefined | string> = () => {
  let signData = storage.rcGetItem(StorageType.local, 'signData')
  signData = signData ? JSON.parse(signData) : {}
  return signData
}

export interface CaptchaImgModel {
  uuid: string
  url: string
}

export interface LoginModel {
  commission: IndexMessage<string>
  corporate: IndexMessage<string>
  agent: UserInfo
  token: string
  sign: string
}

export const verifCodeRequest = (captchaInfo: RequestParams) => {
  return https(false).request<RootObject<CaptchaImgModel>>('agent/getCaptcha', Method.POST, captchaInfo, ContentType.json)
}

export const loginRequest = (userInfo: RequestParams) => {
  const signData = {
    timeStamp: new Date().getTime(),
    randomStr: getRandom(10000, 99999)
  }
  storage.rcSetItem(StorageType.local, 'signData', signData)
  const loginData = Object.assign(signData, userInfo)
  return https(false).request<RootObject<LoginModel>>('/agent/signin', Method.POST, loginData, ContentType.json)
}

export const userInfoRequest = () => {
  return https().request<RootObject<UserInfoModel>>('user/userInfo', Method.GET, undefined, ContentType.form)
}

export const getUsers = (user: RequestParams) => {
  return https().request<RootObject<Users>>('user/getUsers', Method.GET, user, ContentType.form)
}

export const changePassword = (pass: RequestParams) => {
  return https().request<RootObject<Users>>('/agent/auth/editPass', Method.POST, pass, ContentType.json)
}

export const baseMessage = () => {
  return https().request<RootObject<Users>>('/agent/base/message', Method.POST, {}, ContentType.json)
}

export const baseEdit = (msg: RequestParams) => {
  return https().request<RootObject<Users>>('/agent/base/edit', Method.POST, msg, ContentType.json)
}
