
import { getToken } from '@/utils/cookies'

export enum AGENTTYPE {
  COMPANY = 1,
  PERSON = 2
}

export enum AGENTSTATUS {
  FREEZE = 0,
  NORMAL = 1
}
export interface UserState{
  captchacode: string
  captchaimgsrc: string
  loginerror: string
  token: string
  userinfo: UserInfo
  name: string
  avatar: string
  introduction: string
  roles: string[]
  email: string
  timestamp: number
  randomstr: number
  sign: string
  account: number
  bankaccount: string
  company: Company | {}
  activationCode: string
  nowday: string
}

export interface UserInfo{
  pid: number
  id: number
  agent_name: string
  mobile: string
  agent_type: number
  is_enterprise: number
  settlement_cycle: number
  cash_require: string
  agent_status: number
}

export interface AgentInfo{
  agent_level: number
  agent_name: string
  create_time: number
  cur_percent: string
  first_stage: string
  fixed_level: number
  id: number
  month_amount: number
  second_stage: string
  third_stage: string
}
export interface Company{
  company_name?: string
  taxpayer_code?: string
  address?: string
  bank_deposit?: string
  bank_account?: string
  agent_role?: string
  personal_account?: string
  personal_account_type?: string
  is_company: AGENTTYPE
}
export const state: UserState = {
  captchacode: '',
  captchaimgsrc: '',
  loginerror: '',
  token: getToken() || '',
  userinfo: {
    id: 0,
    pid: 0,
    agent_name: '',
    mobile: '',
    agent_type: 0,
    is_enterprise: 0,
    settlement_cycle: 0,
    cash_require: '',
    agent_status: 0
  },
  name: '',
  avatar: '/static/img/avatar.1e6db733.png',
  introduction: '',
  roles: [],
  email: '',
  timestamp: 0,
  randomstr: 0,
  sign: '',
  account: 0,
  bankaccount: '',
  company: {},
  activationCode: '',
  nowday: ''
}
