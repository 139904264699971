
import { createStore, createLogger } from 'vuex'
// import createPersistedState from 'vuex-persistedstate'
import { store as app, AppStore, AppState } from '@/store/modules/app'
import { store as settings, SettingStore, SettingsState } from '@/store/modules/settings'
import { store as permission, PermissionStore, PermissionState } from '@/store/modules/permission'
import { store as user, UserStore, UserState } from '@/store/modules/user'
import { store as tagViews, TagsStore, TagsViewState } from '@/store/modules/tagsview'
import storage, { StorageType } from '@/utils/storage'

export interface RootState {
    app: AppState
    settings: SettingsState
    permission: PermissionState
    user: UserState
    tagViews: TagsViewState
}

export type Store = AppStore<Pick<RootState, 'app'>> & SettingStore<Pick<RootState, 'settings'>>
& PermissionStore<Pick<RootState, 'permission'>> & UserStore<Pick<RootState, 'user'>>
& TagsStore<Pick<RootState, 'tagViews'>>

// Plug in logger when in development environment
const debug = process.env.NODE_ENV !== 'production'
const plugins = debug ? [createLogger({})] : []
// Plug in session storage based persistence
// plugins.push(createPersistedState({ storage: window.sessionStorage }))

export const store = createStore({
  plugins,
  modules: {
    app,
    settings,
    permission,
    user,
    tagViews
  }
})

// 在页面加载时读取localStorage里的状态信息
if (localStorage.getItem('substore')) {
  store.replaceState(Object.assign(store.state, JSON.parse(storage.rcGetItem(StorageType.local, 'substore'))))
}
window.addEventListener('beforeunload', () => {
  const subStore = JSON.parse(JSON.stringify({ user: store.state.user, permission: store.state.permission }))
  subStore.user.roles.splice(0, subStore.user.roles.length)
  storage.rcSetItem(StorageType.local, 'substore', subStore)
})

export function useStore(): Store {
  return store as Store
}
