import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
// import { useI18n } from 'vue-i18n'
import router from '@/router'
import { RouteLocationNormalized } from 'vue-router'
import { useStore } from './store'
import { UserActionTypes } from './store/modules/user/action-types'
import { PermissionActionType } from './store/modules/permission/action-types'
import { ElMessage } from 'element-plus'
import whiteList from './config/default/whitelist'
import storage, { StorageType } from '@/utils/storage'
import { DeviceType } from './store/modules/app/state'
import { AppMutationTypes } from './store/modules/app/mutation-types'
// import settings from '@/config/default/setting.config'
NProgress.configure({ showSpinner: false })

// const getPageTitle = (key: string) => {
//   const i18n = useI18n()
//   const title = settings.title
//   const hasKey = i18n.te(`route.${key}`)
//   if (hasKey) {
//     const pageName = i18n.t(`route.${key}`)
//     return `${pageName} - ${title}`
//   }
//   return `${title}`
// }
router.beforeEach(async(to: RouteLocationNormalized, _: RouteLocationNormalized, next: any) => {
  const store = useStore()
  // Start progress bar
  NProgress.start()
  // Determine whether the user has logged in
  if (useStore().state.user.token) {
    if (to.path === '/login') {
      // If is logged in, redirect to the home page
      next({ path: '/' })
      NProgress.done()
    } else {
      // 30分钟未操作，登录失效
      const now = new Date().getTime()
      const past = storage.rcGetItem(StorageType.local, 'now')
      if (past && (now - (Number(past)) > 1800000)) {
        useStore().dispatch(UserActionTypes.ACTION_LOGIN_OUT, undefined)
        next(`/login?redirect=${to.path}`)
        storage.rcRemoveItem(StorageType.local, 'now')
      } else {
        storage.rcSetItem(StorageType.local, 'now', now)
      }

      // Check whether the user has obtained his permission roles
      if (store.state.user.roles.length === 0) {
        try {
          // Note: roles must be a object array! such as: ['alevelDistributor'] ,['alevelDivider'], ['secondaryDivider']
          // alevelDivider：一级分成商
          // secondaryDivider：二级分成商
          // alevelDistributor： 一级分销商
          // secondaryDistributor：二级分销商
          // 获取用户角色
          let roles = store.state.user.roles
          if (!roles.length && localStorage.getItem('roles')) {
            roles = JSON.parse(storage.rcGetItem(StorageType.local, 'roles'))
            !roles.length && roles.push('alevelDivider')
            store.dispatch(UserActionTypes.ACTION_GET_USER_INFO, roles)
          }
          // Generate accessible routes map based on role
          store.dispatch(PermissionActionType.ACTION_SET_ROUTES, roles)
          // Dynamically add accessible routes
          store.state.permission.dynamicRoutes.forEach((route) => {
            router.addRoute(route)
          })
          // Hack: ensure addRoutes is complete
          // Set the replace: true, so the navigation will not leave a history record
          next({ ...to, replace: true })
        } catch (err) {
          // Remove token and redirect to login page
          store.dispatch(UserActionTypes.ACTION_RESET_TOKEN, undefined)
          // storage.rcRemoveItem(StorageType.session, 'store')
          ElMessage.error(err.toString() || '未知错误，请稍后重试')
          next(`/login?redirect=${to.path}`)
          NProgress.done()
        }
      } else {
        // console.log('next')
        next()
      }
    }
  } else {
    // Has no token
    if (whiteList.indexOf(to.path) !== -1) {
      // In the free login whitelist, go directly
      next()
    } else {
      // Other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // console.log(to)
  // Finish progress bar
  // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
  NProgress.done()
  // 移动端切换路由后默认收起导航栏
  const store = useStore()
  if (store.state.app.device === DeviceType.Mobile) {
    store.commit(AppMutationTypes.CLOSE_SIDEBAR, false)
  }
  // set page title
  // document.title = getPageTitle(to.meta.title as string)
})
