
import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/Index.vue'

const alevelAgentRouter: Array<RouteRecordRaw> = [
  /* 看板 */
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    meta: {
      roles: ['alevelDistributor', 'alevelDivider']
    },
    children: [
      {
        path: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Index.vue'),
        name: 'Dashboard',
        meta: {
          title: 'dashboard',
          icon: '#icondashboard',
          order: 1,
          roles: ['alevelDistributor', 'alevelDivider']
        }
      }
    ]
  },
  /* 订单 */
  {
    path: '/order',
    component: Layout,
    redirect: '/order/index',
    name: 'Order',
    meta: {
      title: 'order',
      icon: '#iconexample',
      roles: ['alevelDistributor', 'alevelDivider'],
      affix: true
    },
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "order" */ '@/views/order/Index.vue'),
        name: 'OrderIndex',
        meta: {
          title: 'order',
          icon: '#iconexample',
          affix: true,
          breadcrumb: false,
          roles: ['alevelDistributor', 'alevelDivider']
        }
      },
      {
        path: 'secondaryList/:id',
        component: () => import(/* webpackChunkName: "SecondaryList" */ '@/views/order/Index.vue'),
        name: 'SecondaryList',
        meta: {
          title: 'secondaryList',
          roles: ['alevelDistributor', 'alevelDivider'],
          hidden: true,
          activeMenu: '/order/index'
        }
      }
    ]
  },
  /* 资金结算 */
  {
    path: '/finace',
    component: Layout,
    redirect: '/finace/index',
    name: 'Finace',
    meta: {
      title: 'finace',
      icon: '#iconcomponent',
      order: 2,
      affix: true,
      roles: ['alevelDistributor', 'alevelDivider']
    },
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "order" */ '@/views/finace/Index.vue'),
        name: 'FinaceIndex',
        meta: {
          title: 'finace',
          icon: '#iconcomponent',
          order: 2,
          affix: true,
          roles: ['alevelDistributor', 'alevelDivider'],
          breadcrumb: false
        }
      },
      {
        path: 'withdraw',
        component: () => import(/* webpackChunkName: "example-edit" */ '@/views/finace/Withdraw.vue'),
        name: 'Withdraw',
        meta: {
          title: 'withdraw',
          activeMenu: '/finace/index',
          order: 3,
          hidden: true,
          roles: ['alevelDistributor', 'alevelDivider']
        }
      },
      {
        path: 'accountinfo',
        component: () => import(/* webpackChunkName: "example-edit" */ '@/views/finace/Accountinfo.vue'),
        name: 'Accountinfo',
        meta: {
          title: 'accountinfo',
          activeMenu: '/finace/index',
          hidden: true,
          order: 4,
          roles: ['alevelDistributor', 'alevelDivider']
        }
      },
      {
        path: 'rejected',
        component: () => import(/* webpackChunkName: "example-edit" */ '@/views/finace/Condition.vue'),
        name: 'Rejected',
        meta: {
          title: 'rejected',
          activeMenu: '/finace/index',
          hidden: true,
          order: 5,
          roles: ['alevelDistributor', 'alevelDivider']
        }
      },
      {
        path: 'unpaid',
        component: () => import(/* webpackChunkName: "example-edit" */ '@/views/finace/Condition.vue'),
        name: 'Unpaid',
        meta: {
          title: 'unpaid',
          activeMenu: '/finace/index',
          hidden: true,
          roles: ['alevelDistributor', 'alevelDivider']
        }
      },
      {
        path: 'complete',
        component: () => import(/* webpackChunkName: "example-edit" */ '@/views/finace/Condition.vue'),
        name: 'Complete',
        meta: {
          title: 'complete',
          activeMenu: '/finace/index',
          hidden: true,
          roles: ['alevelDistributor', 'alevelDivider']
        }
      },
      {
        path: 'undone',
        component: () => import(/* webpackChunkName: "example-edit" */ '@/views/finace/Condition.vue'),
        name: 'ndone',
        meta: {
          title: 'undone',
          activeMenu: '/finace/index',
          hidden: true,
          roles: ['alevelDistributor', 'alevelDivider']
        }
      }
    ]
  },
  /* 推广链接 */
  {
    path: '/spread',
    component: Layout,
    redirect: '/spread/index',
    name: 'Spread',
    meta: {
      title: 'spread',
      icon: '#icontab',
      roles: ['alevelDistributor', 'alevelDivider']
    },
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "permission-page" */ '@/views/spread/Index.vue'),
        name: 'IndexLink',
        meta: {
          title: 'indexLink',
          icon: '#icontab',
          breadcrumb: false
        }
      }
      // {
      //   path: 'edit',
      //   component: () => import(/* webpackChunkName: "permission-directive" */ '@/views/spread/Edit.vue'),
      //   name: 'EditLink',
      //   meta: {
      //     title: 'editLink'
      //     // if do not set roles, means: this page does not require permission
      //   }
      // }
    ]
  },
  /* 基本信息 */
  {
    path: '/profile',
    component: Layout,
    redirect: '/profile/index',
    name: 'Profile',
    meta: {
      title: 'profile',
      icon: '#iconguide',
      roles: ['alevelDistributor', 'alevelDivider']
    },
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "permission-page" */ '@/views/profile/Index.vue'),
        name: 'ProfileIndex',
        meta: {
          title: 'profile',
          icon: '#iconguide',
          breadcrumb: false
        }
      },
      {
        path: '/changepassword/index',
        component: () => import(/* webpackChunkName: "userManager" */ '@/views/user-manager/changepassword/Index.vue'),
        name: 'Changepassword',
        meta: {
          title: 'changePassword',
          hidden: true,
          activeMenu: '/profile/index',
          roles: ['alevelDistributor', 'alevelDivider']
        }
      }
    ]
  },
  /* 二级代理 */
  {
    path: '/secondary',
    component: Layout,
    redirect: '/secondary/index',
    name: 'Secondary',
    meta: {
      title: 'secondary',
      icon: '#iconnested',
      roles: ['alevelDistributor', 'alevelDivider']
    },
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "secondary-page" */ '@/views/secondary/Index.vue'),
        name: 'SecondaryIndex',
        meta: {
          title: 'secondary',
          icon: '#iconnested',
          breadcrumb: false
        }
      },
      {
        path: 'add',
        component: () => import(/* webpackChunkName: "secondary-add" */ '@/views/secondary/Add.vue'),
        name: 'Add',
        meta: {
          title: 'add',
          activeMenu: '/secondary/index',
          hidden: true
        }
      },
      {
        path: 'batchadd',
        component: () => import(/* webpackChunkName: "secondary-batchadd" */ '@/views/secondary/Batchadd.vue'),
        name: 'Batchadd',
        meta: {
          title: 'batchadd',
          activeMenu: '/secondary/index',
          hidden: true
        }
      },
      {
        path: 'basicinfo/:id',
        component: () => import(/* webpackChunkName: "secondary-basicinfo" */ '@/views/secondary/Basicinfo.vue'),
        name: 'Basicinfo',
        meta: {
          title: 'basicinfo',
          activeMenu: '/secondary/index',
          hidden: true
        }
      }
    ]
  },
  /* 账号流水 */
  {
    path: '/account',
    component: Layout,
    redirect: '/account/index',
    name: 'Account',
    meta: {
      title: 'account',
      icon: '#icontable',
      roles: ['alevelDistributor', 'alevelDivider']
    },
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "account-page" */ '@/views/account/Index.vue'),
        name: 'AccountIndex',
        meta: {
          title: 'account',
          icon: '#icontable',
          breadcrumb: false
        }
      }
    ]
  }
]

export default alevelAgentRouter
